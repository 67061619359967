import React, { useState, useEffect, useRef } from "react";
import { TopNavigation } from "../../components/NavBar/TopNavigation";
import Instruct from "../../components/portal/Instruct";
import { getStage2Questions } from "../../service/mlAPI";
import { useNavigate } from "react-router-dom";
import TestingNavbar from "../../components/NavBar/TestingNavbar";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { fetchRolequestions } from "../../service/api";

export default function InstructionA() {
  const streamRef = useRef(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const questionsArray = useRef([]);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [seconds, setSeconds] = useState(60);
  const navigate = useNavigate();
  const location = useLocation();
  const { role, experience } = location.state;
  const DATA = {
    ins1: `${role}`,
    ins2: "This segment seeks to determine your designated role for this examination.",
    ins3: "Number of Questions",
    ins4:
      "You'll receive two subject-related questions for " +
      `${role}` +
      " and are expected to respond promptly",
    ins5: "Time",
    ins6: "You will be allotted one minute for each question to contemplate and provide your response regarding this role.",
    ins7: "Assessment of Role",
    ins8: "You will encounter questions tailored to assess your suitability for the designated role.",
  };

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const getQuestionsFromOpenAI = async (data) => {
    try {
      let q5 = await fetchRolequestions(data, role, experience);
      questionsArray.current = [q5[0].ques, q5[1].ques];
      //console.log(questionsArray.current);
    } catch (error) {
      ////console.log("Error getting questions from OpenAI", error.message);
      toast.error("Error Occured! Send Screenshot to Telegram!");
    }
  };

  const getCameraPermission = async () => {
    let token = getCookieAndRole();
    await getQuestionsFromOpenAI(token.token);
    setRecordedVideo(null);
    if ("MediaRecorder" in window) {
      try {
        const videoConstraints = {
          audio: false,
          video: true,
        };
        const audioConstraints = { audio: true };
        const audioStream = await navigator.mediaDevices.getUserMedia(
          audioConstraints
        );
        const videoStream = await navigator.mediaDevices.getUserMedia(
          videoConstraints
        );
        setButtonDisabled(false); // Enable the button when streams are obtained
      } catch (err) {
        // Handle camera access errors
        console.error("Error accessing camera", err.message);
        toast.error(
          "Please give proper access for audio and camera for the test to start"
        );
        navigate(-1);
        setButtonDisabled(true);
      }
    } else {
      // Handle lack of MediaRecorder API support
      console.error("The MediaRecorder API is not supported in your browser.");
      toast.error(
        "Please give proper access for audio and camera for the test to start"
      );
      navigate(-1);
      setButtonDisabled(true);
    }
  };

  const handleStartTest = () => {
    if (!buttonDisabled) {
      navigate("/stage2", {
        state: { questionsArray: questionsArray.current },
      });
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    getCameraPermission();

    // Start the countdown timer
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Cleanup the timer on unmount
    return () => clearInterval(timer);
  }, []);

  // Redirect if timer reaches 0
  useEffect(() => {
    if (seconds === 0) {
      getQuestionsFromOpenAI();
    }
  }, [seconds]);

  return (
    <div className="bg-gray-100 h-screen">
      <TestingNavbar
        showAvatar={true}
        handleLogout={() => navigate("/login")}
      />
      <div className="px-12">
        <Instruct instructions={DATA} />
        {/* Start Test Button */}
        <div className="mt-4 flex justify-center">
          {buttonDisabled ? (
            <div className="p-2 border-4 rounded-lg text-center border-black">
              <div className="text-4xl font-bold text-gray-800">
                {formatTime(seconds)}
              </div>
            </div>
          ) : (
            <button
              disabled={buttonDisabled}
              onClick={handleStartTest}
              className={`${
                buttonDisabled
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-700"
              } text-white font-bold py-2 px-4 rounded`}
            >
              Start Test
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
